// transition
@mixin transition($type,$date) {
     transition:$type $date;
     -webkit-transition:$type $date;
     -moz-transition:$type $date;
     -o-transition:$type $date;
}
// scale
@mixin scale($value){
     transform:scale($value);
     -ms-transform:scale($value);
     -webkit-transform:scale($value);
     -moz-transform:scale($value);
     -o-transform:scale($value);
}
// rotate
@mixin rotate($value){
     transform:rotate($value);
     -ms-transform:rotate($value);
     -webkit-transform:rotate($value);
     -moz-transform:rotate($value);
     -o-transform:rotate($value);
}


@charset "utf-8";

*{padding:0;margin:0;box-sizing:border-box;background-repeat:no-repeat;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:'PingFang SC', sans-serif,"Microsoft YaHei","微软雅黑",Arial;}
img{border:none;vertical-align:middle;display:inline-block;max-width:100%;max-height:100%;}
html{@include transition(all,.4s);}
body{max-width:1920px;background:#fff;color:#000;font-size:14px;margin-left:auto;margin-right:auto;line-height:1.1;}
ul,ol{list-style-type:none;}
a{text-decoration:none;color:inherit;@include transition(all,.155s);}
a:hover{text-decoration:none;color:#0072c1;}
.clear{clear:both;font-size:0;height:0;visibility:hidden;line-height:0;}
.fl{float:left;}
.fr{float:right;}
.none{display:none !important;}

.container{
     width:1220px;
     padding-left:10px;
     padding-right:10px;
     margin-left:auto;
     margin-right:auto;
     &:before,&:after{
          content:'';
          display:table;
          width:100%;
          clear:both;
          font-size:0;
     }
}

/*导航按钮*/
.navbtn{
     display:none;
     position:absolute;
     z-index:99;
     width:auto;
     height:auto;
     padding:0 7px;
     border-radius:5px;
     background-color:rgba($color:#0072c1, $alpha:1);
     @include transition(all,.3s);
     span{
          display:block;
          width:35px;
          height:4px;
          margin:8px 0;
          border-radius:5px;
          background-color:#fff;
          @include transition(all,.3s);
     }
     &.navclose{
          span{
               &:nth-child(1){
                    -moz-transform: translateY(12px) rotate(45deg);
                    -ms-transform: translateY(12px) rotate(45deg);
                    -webkit-transform: translateY(12px) rotate(45deg);
                    transform: translateY(12px) rotate(45deg);
               }
               &:nth-child(2){opacity:0;}
               &:nth-child(3){
                    -moz-transform: translateY(-12px) rotate(-45deg);
                    -ms-transform: translateY(-12px) rotate(-45deg);
                    -webkit-transform: translateY(-12px) rotate(-45deg);
                    transform: translateY(-12px) rotate(-45deg);
               }
          }
     }
}

/* 头部 */
.header{
     border-top: 8px #0072c1 solid;
     .logo{
          display: flex;
          display: -webkit-flex;
          flex-flow:column nowrap;
          justify-content: center;
          float: left;
          width: (620%/1200)*100;
          height: 140px;
          font-size:62.5%;
          img{
               float: left;
               margin-right: 15px;
          }
          h1{
               color:#252525;
               font-size: 3em;
               font-weight: bold;
          }
          h2{
               opacity: .5;
               padding-top: 10px;
               color:#252525;
               font-size: 1.6em;
          }
     }
     .search{
          float: right;
          width: 220px;
          padding-top: (140px-35)/2;
          overflow: hidden;
          .text{
               float: left;
               display: block;
               width: 180px;
               height: 35px;
               line-height: 33px;
               padding-left: 10px;
               padding-right: 10px;
               border-top: 1px #ccc solid;
               border-left: 1px #ccc solid;
               border-bottom: 1px #ccc solid;
               border-right: none;
               background: none;
               outline: none;
               font-size: 14px;
               border-radius: 0;
               @include transition(all,.15s);
               &:focus{border-color: #0072c1;}
          }
          .submit{
               float: left;
               display: block;
               width: 40px;
               height: 35px;
               line-height: 35px;
               font-size: 0;
               background:{
                    color: #0072c1;
                    image:url(../images/search.png);
                    position: center center;
                    size:auto 21px;
               }
               border-radius: 0;
               border:none !important;
               cursor: pointer;
               outline: none;
               @include transition(all,.15s);
               &:active{opacity: .9;}
          }
     }
}

/* 导航条 */
.navbar{
     border-top: 1px #d0d0d0 solid;
     .nav{
          height: 65px;
          a{
               float: left;
               display: block;
               width: (100%/8);
               line-height: 60px;
               text-align: center;
               border-bottom: 5px transparent solid;
               color:#333;
               font-size: 16px;
               &:hover,&.current{
                    color:#0072c1;
                    border-color:#0072c1;
               }
          }
     }
}

/* banner */
.banner{
     position: relative;
     height: 545px;
     overflow: hidden;
     .swiper-slide{
          background: {
               position: center center;
               size:cover;
          }
     }
}

/* 首页 */
.index-wrapper{
     padding-bottom: 90px;
     .box-title{
          padding-top: 50px;
          text-align: center;
          h3{
               color:#333;
               font-size: 30px;
          }
          .en{
               position: relative;
               display: inline-block;
               height: 20px;
               line-height: 20px;
               margin-top: 10px;
               color:#b5b5b5;
               font-size: 18px;
               text-transform: uppercase;
               &:before,&:after{
                    content:'';
                    position: absolute;
                    top: 10px;
                    width: 45px;
                    height: 2px;
                    background-color: #d2d2d2;
               }
               &:before{left: -65px;}
               &:after{right: -65px;}
          }
     }
     // 视频中心
     .section-box1{
          .slide-wrapper{
               position: relative;
               margin-top: 50px;
               .button-prev,.button-next{
                    position: absolute;
                    top: 50%;
                    margin-top: -17.5px;
                    width: 35px;
                    height: 35px;
                    background: {
                         position: center center;
                         size:contain;
                    }
                    cursor: pointer;
                    @include transition(all,.15s);
                    &:hover{opacity: .5;}
               }
               .button-prev{left:-80px;background-image: url(../images/arrow_v_s_l.png);}
               .button-next{right:-80px;background-image: url(../images/arrow_v_s_r.png);}
          }
          .video-center-slide{
               position: relative;
               overflow: hidden;
               .swiper-slide{
                    .pic{
                         position: relative;
                         display: block;
                         overflow: hidden;
                         img{
                              display: block;
                              width: 100%;
                              @include transition(all,1s);
                         }
                         &:before{
                              opacity: 0;
                              content:'';
                              position: absolute;
                              z-index: 9;
                              top: 0;
                              left: 0;
                              width: 100%;
                              height: 100%;
                              background: {
                                   color:rgba($color:#000, $alpha:.4);
                                   image:url(../images/play_icon.png);
                                   position: center center;
                                   repeat:no-repeat;
                                   size:60px auto;
                              }
                              @include transition(all,.2s);
                         }
                         &:after{
                              opacity: 0;
                              content:'';
                              position: absolute;
                              z-index: 10;
                              top: 0;
                              left: 0;
                              right:0;
                              bottom:0;
                              margin:auto;
                              width: 100%;
                              height: 100%;
                              border:2px #fff solid;
                              @include transition(all,.2s);
                         }
                    }
                    .title{
                         display: block;
                         line-height: 60px;
                         border-bottom: 2px #bfbfbf solid;
                         white-space: nowrap;
                         text-overflow: ellipsis;
                         overflow: hidden;
                         color:#000;
                         font-size: 16px;
                    }
                    &:hover{
                         .pic{
                              img{@include scale(1.2);}
                              &:before,&:after{opacity: 1;}
                              &:after{
                                   width: 93%;
                                   height: 92%;
                              }
                         }
                         .title{
                              color:#0072c1;
                              border-color: #0072c1;
                         }
                    }
               }
          }
          .more{
               display: block;
               width: 160px;
               line-height: 36px;
               margin-left: auto;
               margin-right: auto;
               margin-top: 50px;
               text-align: center;
               border:2px #dfdfdf solid;
               border-radius: 2px;
               font-size: 18px;
               &:hover{
                    border-color: #0072c1;
               }
          }
     }
     // 关于益刃
     .section-box2{
          position: relative;
          margin-top: 60px;
          overflow: hidden;
          .box-title{
               text-align: left;
               h3{
                    margin-top: 10px;
                    color:#fff;
                    font-size: 30px;
               }
          }
          .container{
               position: absolute;
               z-index: 9;
               left: 0;
               right: 0;
               margin:auto;
          }
          .fl,.fr{
               width: 50%;
               height: 550px;
               padding-top: 145px;
               background: {
                    image:url(../images/i_a_l_bg.jpg);
                    position: center center;
                    size:cover;
               }
          }
          .fr{
               background-image: url(../images/i_a_r_bg.jpg);
               .content{
                    float: left;
                    .text{
                         color:#333;
                         background-color: rgba($color:#fff, $alpha:.9);
                         &:before{
                              left: 0;
                              right: -20px;
                              border-color: rgba($color:#fff,$alpha:.7);
                              border-right-width:10px;
                              border-left: none;
                         }
                    }
               }
          }
          .content{
               float: right;
               width: 64%;
               .text{
                    position: relative;
                    display: flex;
                    display: -webkit-flex;
                    flex-flow:column nowrap;
                    justify-content: center;
                    height: 235px;
                    line-height: 2.4;
                    padding-left: 30px;
                    padding-right: 30px;
                    margin-top: 50px;
                    color:#fff;
                    font-size: 16px;
                    background-color: #0072c1;
                    &:before{
                         content:'';
                         position: absolute;
                         top: -20px;
                         left: -20px;
                         width: calc(100% + 10px);
                         height: 110%;
                         border-color: rgba($color:#fff,$alpha:.1);
                         border-style: solid;
                         border-top-width:10px;
                         border-left-width:10px;
                         border-bottom-width:10px;
                         border-right-width: 0;
                    }
               }
               .more{
                    display: block;
                    width: 135px;
                    height: 35px;
                    line-height: 33px;
                    text-align: center;
                    margin-top: 45px;
                    border:1px #fff solid;
                    color:#fff;
                    font-size: 16px;
                    &:hover{
                         color:#0072c1;
                         background-color: #fff;
                    }
               }
          }
     }
     // 产品推荐
     .section-box3{
          .tab-nav{
               margin-top: 30px;
               overflow: hidden;
               a{
                    float: left;
                    display: block;
                    height: 45px;
                    line-height: 45px;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-align: center;
                    color:#666;
                    font-size: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &:hover{color:#0072c1;}
                    &.current{
                         color:#fff;
                         background-color: #0072c1;
                    }
               }
          }
          .tab-slide{
               position: relative;
               margin-top: 30px;
               .swiper-slide-active{z-index: 9;}
               .one-more{
                    position: absolute;
                    top: -(30+45px);
                    right: 0;
                    display: block;
                    height: 45px;
                    line-height: 45px;
                    color:#666;
                    font-size: 14px;
                    &:hover{color:#0072c1;}
               }
          }
          .pro-slide{
               position: static;
               overflow: hidden;
               .swiper-slide{pointer-events: auto;}
               .button-prev,.button-next{
                    position: absolute;
                    z-index: 999;
                    top: 50%;
                    margin-top: -12.5px;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    font-size: 0;
                    cursor: pointer;
               }
               .button-prev{left: -55px;}
               .button-next{right: -55px;}
               a{
                    display: block;
                    &:hover{
                         .pic{img{@include scale(1.2);}}
                         .info{background-color: #0072c1;}
                    }
               }
               .pic{
                    overflow: hidden;
                    img{
                         display: block;
                         width: 100%;
                         @include transition(all,.5s);
                    }
               }
               .info{
                    text-align: center;
                    padding-bottom: 30px;
                    background-color: #222;
                    @include transition(all,.2s);
                    span{display: block;color:#fff;}
                    .xh{
                         padding-left: 10px;
                         padding-right: 10px;
                         padding-top: 20px;
                         font-size: 16px;
                         white-space: nowrap;
                         text-overflow: ellipsis;
                         overflow: hidden;
                    }
                    .gn{
                         padding-left: 10px;
                         padding-right: 10px;
                         padding-top: 13px;
                         font-size: 14px;
                         white-space: nowrap;
                         text-overflow: ellipsis;
                         overflow: hidden;
                    }
                    .more{
                         width: 120px;
                         height: 30px;
                         line-height: 30px;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 15px;
                         color:#222;
                         font-size: 14px;
                         background-color: #fff;
                    }
               }
          }
     }
     // 益刃课堂
     .section-box4{
          .container{overflow: hidden;padding-bottom: 5px;}
          .tab-nav{
               height: 70px;
               padding-left: 10px;
               border-bottom: 1px #dcdcdc solid;
               a{
                    float: left;
                    display: block;
                    line-height: 70px;
                    margin-right: 45px;
                    color:#666;
                    font-size: 18px;
                    &:hover,&.current{color:#0072c1;}
                    &.current{font-weight: bold;}
               }
          }
          .tab-slide{
               position: relative;
               margin-top: 40px;
               .one-more{
                    position: absolute;
                    right: 0;
                    top: -110px;
                    margin-top: (70px-20)/2;
                    height: 20px;
                    line-height: 20px;
                    color:#666;
                    font-size: 14px;
                    &:hover{color:#0072c1;}
               }
               .swiper-slide{position: static;}
          }
          .room-pic-slide{
               position: relative;
               float: left;
               width: (495%/1200)*100;
               height: 415px;
               overflow: hidden;
               .swiper-slide{
                    background: {
                         position: center center;
                         size:cover;
                    }
               }
               .swiper-pagination{
                    span{
                         opacity: 1;
                         width: 32px !important;
                         height: 5px !important;
                         background-color: #fff;
                         border-radius: 0;
                         font-size: 0;
                         &.swiper-pagination-bullet-active{background-color: #0072c1;}
                    }
               }
               .pic-info{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom:30px;
                    margin:auto;
                    width: 92%;
                    height: auto;
                    max-height: 140px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    background-color: rgba($color:#fff, $alpha:.95);
                    .title{
                         display: inline-block;
                         white-space: nowrap;
                         text-overflow: ellipsis;
                         overflow: hidden;
                         color:#3c3c3c;
                         font-size: 24px;
                         &:hover{color:#0072c1;}
                    }
                    .text{
                         line-height: 24px;
                         margin-top: 10px;
                         color:#999;
                         font-size: 14px;
                    }
               }
          }
     }
}
// 文字列表
.text-list{
     float: right;
     width: (650%/1200)*100;
     margin-top: -30px;
     .item{
          padding-top: 30px;
          padding-bottom: 30px;
          overflow: hidden;
          border-bottom: 1px #e8e8e8 solid;
          &:hover{
               .date b,.title,.text{color:#0072c1;}
          }
     }
     .date{
          float: left;
          max-width: (120%/650)*100;
          text-align: right;
          color:#333;
          font-size: 16px;
          b{
               display: block;
               padding-bottom: 5px;
               font-size: 36px;
               font-weight: normal;
               @include transition(all,.15s);
          }
     }
     .info{
          float: right;
          width: (650%-100-40)/650*100;
     }
     .title{
          display: inline-block;
          color:#3c3c3c;
          font-size: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover{color:#0072c1;}
     }
     .text{
          height: 40px;
          line-height: 20px;
          margin-top: 25px;
          color:#a0a0a0;
          font-size: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          @include transition(all,.15s);
     }
}

/* 内页 */
.inside-banner{
     height: 350px;
     background: {
          position: center center;
          size:cover;
     }
}

// 面包屑导航
.crumbs{
     line-height: 20px;
     padding-top: 15px;
     padding-bottom: 15px;
     margin-bottom: 30px;
     color:#7c7c7c;
     font-size: 14px;
     border-bottom: 1px #e5e5e5 solid;
}

// 内页左侧
.inside-left{
     float: left;
     width: (240%/1200)*100;
     .item{margin-bottom: 30px;}
     .download{h3{background-color: #2c2f34;}}
     h3{
          height: 50px;
          line-height: 50px;
          padding-left: 30px;
          background-color: #0072c1;
          color:#fff;
          font-size: 16px;
          font-weight: normal;
          img{
               float: left;
               display: block;
               height: 25px;
               margin-top: 12.5px;
               margin-right: 30px;
          }
     }
     ul{
          li{
               a{
                    position: relative;
                    display: block;
                    line-height: 20px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 75px;
                    color:#333;
                    font-size: 14px;
                    border-bottom: 1px #aaa dotted;
                    &:before{
                         content:'';
                         position: absolute;
                         left: 40px;
                         top: (44px-10)/2;
                         width: 9px;
                         height: 9px;
                         background-color: #333;
                         @include transition(all,.15s);
                    }
               }
               &:hover,&.current{
                    a{
                         color:#0072c1;
                         border-color: #0072c1;
                         &:before{background-color: #0072c1;}
                    }
               }
          }
     }
}

// 内页右侧
.inside-right{
     float: right;
     width: (895%/1200)*100;
     overflow: hidden;
     .h-title{
          color:#333;
          font-size: 25px;
          span{
               display: inline-block;
               padding-left: 10px;
               color:#7c7c7c;
               text-transform: uppercase;
          }
     }
}

/* 产品列表 */
.list-product{
     margin-left: -5px;
     margin-right: -5px;
     overflow: hidden;
     .item{
          float: left;
          width: 33.33%;
          padding-left: 5px;
          padding-right: 5px;
          margin-top: 30px;
          overflow: hidden;
          a{
               display: block;
               background-color: #999;
               &:hover{
                    .pic{img{@include scale(1.2);}}
                    .info{
                         background-color: #0072c1;
                         background-position: 92% center;
                    }
               }
          }
          .pic{
               height: 260px;
               overflow: hidden;
               img{
                    display: block;
                    width: 100%;
                    max-height: none;
                    @include transition(all,.5s);
               }
          }
          .info{
               padding-top: 15px;
               padding-bottom: 15px;
               padding-left: 25px;
               padding-right: 45px;
               background:{
                    color: #222;
                    image:url(../images/arrow_right.png);
                    position: 95% center;
                    size:auto 16px;
               }
               @include transition(all,.3s);
               span{
                    display: block;
                    line-height: 25px;
                    color:#fff;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &.xh{font-size: 16px;}
                    &.gn{font-size: 14px;}
               }
          }
     }
}

/* 新闻列表 */
.list-news{
     float: none;
     width: 100%;
     margin-top: 10px;
     .item{
          padding-top: 30px;
          padding-bottom: 30px;
          overflow: hidden;
          border-bottom: 1px #e8e8e8 solid;
          &:hover{
               .date b,.title,.text{color:#0072c1;}
          }
     }
     .date{
          float: left;
          max-width: (120%/650)*100;
          text-align: right;
          color:#333;
          font-size: 16px;
          b{
               display: block;
               padding-bottom: 5px;
               font-size: 36px;
               font-weight: normal;
               @include transition(all,.15s);
          }
     }
     .info{
          float: right;
          width: (650%-100-40)/650*100;
     }
     .title{
          display: inline-block;
          color:#3c3c3c;
          font-size: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover{color:#0072c1;}
     }
     .text{
          height: 40px;
          line-height: 20px;
          margin-top: 25px;
          color:#a0a0a0;
          font-size: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          @include transition(all,.15s);
     }
}

/* 分页 */
.paging{
     padding-top: 50px;
     padding-bottom: 100px;
     text-align: center;
     font-size: 0;
     span,a{
          display: inline-block;
          height: 30px;
          line-height: 28px;
          padding-left: 12px;
          padding-right: 12px;
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          border:1px #ccc solid;
          color:#666;
          font-size: 14px;
     }
     a:hover,.on{
          color:#fff;
          background-color: #0072c1;
          border-color: #0072c1;
     }
}

/* 底部 */
.footer{
     padding-top: 45px;
     background-color: #2c2f34;
     .nav{
          text-align: center;
          font-size:0;
          a{
               display: inline-block;
               margin-left: 25px;
               margin-right: 25px;
               color:#fff;
               font-size: 16px;
          }
     }
     .contact{
          margin-top: 45px;
          border-right: 1px #4b4e52 solid;
          overflow: hidden;
          .item{
               float: left;
               width: 33.33%;
               text-align: right;
               padding-top: 15px;
               padding-left: 15px;
               padding-right: 15px;
               padding-bottom: 10px;
               border-top: 1px #4b4e52 solid;
               border-left: 1px #4b4e52 solid;
               border-bottom: 1px #4b4e52 solid;
               font-size: 0;
               p{
                    line-height: 2;
                    margin-top: 15px;
                    color:#fff;
                    font-size: 16px;
               }
          }
     }
     .copyright{
          opacity: .6;
          text-align: center;
          padding-top: 35px;
          padding-bottom: 20px;
          line-height: 20px;
          color:#fff;
          font-size: 14px;
     }
}

// body{padding-bottom: 300px;}